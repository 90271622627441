/* **************************************
 *  LIBRARIES
 * ************************************ */

import React from 'react'
import { Helmet } from 'react-helmet'

/**
 * Returns all public facing meta tags for better SEO on public pages through SSR nothing is dynamic
 * @param {Object} metaTags {title, description, image, video, canonicalUrl}
 * @returns {Element} '<Helmet/>'
 */

const MetaTagsDefault = (metaTags) => {
	const meta = {
		title: metaTags.title || `Create Your Own Gift Video for Special Occasions | VidDay 🎁`,
		description: metaTags.description || ``,
		image: metaTags.image || `https://www.vidday.com/assets/images/seo/Vidday-The-Surprise-Video-Gift_og.jpg`,
		video:
			metaTags.video ||
			`https://static.vidday.com/videos/how-to-make-a-video-montage-as-a-gift_make-a-video-gift-for-birthdays-weddings-retirements-and-more_720.mp4`,

		jsonSchema:
			metaTags.jsonSchema ||
			`
		{
			"@context": "http://schema.org",
			"@type": "SoftwareApplication",
			"name": "Create Your Own Gift Video for Special Occasions | VidDay 🎁",
			"operatingSystem": "Windows, OS X, Linux, Android, iOS",
			"applicationCategory": "WebApplication",
			"applicationSubCategory": "http://www.vidday.com",
			"image": "https://static.vidday.com/seo_images/VidDay_Birthday_Video_Montage.webp",
			"description": "VidDay, a video maker for any special occasion! Get everyone to join in on the surprise video to celebrate that special day — no app to download! Live Support.",
			"offers": {
				"@type": "Offer",
				"price": "5.00",
				"priceCurrency": "USD",
				"url": "https://share.vidday.com/start-a-vidday"
			},
			"creator": {
				"@context": "http://schema.org",
				"@type": "Organization",
				"name": "Vidday Media Inc.",
				"alternateName": "VidDay",
				"url": "https://share.vidday.com",
				"logo": "/assets/images/vidday_logo.svg",
				"sameAs": [
					"https://www.facebook.com/ViddayGift",
					"https://twitter.com/ViddayGift",
					"https://www.instagram.com/viddaygift/",
					"https://www.youtube.com/channel/UCoq7kgNLjUTCL2b94zW-L1A",
					"https://www.youtube.com/c/Vidday",
					"https://www.pinterest.ca/ViddayGift/"
				]
			},
			"aggregateRating": {
				"@type": "AggregateRating",
				"ratingValue": "4.9",
				"ratingCount": "1268"
			}
		}
		`,
	}

	return (
		<Helmet>
			<title>{meta.title}</title>

			{/* Removed for dynamic search engine descriptions */}
			<meta name="description" content="" />

			{/* Facebook */}
			<meta property="fb:app_id" content="1826421140802874" />
			<meta property="og:type" content="product" />
			<meta property="og:title" content={meta.title} />
			<meta property="og:url" content={meta.canonicalUrl} />
			{/* <meta property="og:video" content={meta.video} /> Removed to prevent viewing of video in sms app, bring them to the site */}
			<meta property="og:image" itemProp="image" content={meta.image} />
			<meta property="og:description" content={meta.description} />

			{/* Twitter */}
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:site" content="@viddaygift" />
			<meta name="twitter:title" content={`${meta.title}`} />
			<meta name="twitter:description" content={`${meta.description}`} />
			<meta name="twitter:image" content={`${meta.image}`} />

			<meta name="classification" content="Greeting cards" />
			<meta name="category" content="general" />
			<meta name="subject" content="Group Video Gift Maker" />
			<meta name="HandheldFriendly" content="true" />
			<meta name="MobileOptimized" content="320" />

			<script type="application/ld+json" dangerouslySetInnerHTML={{ __html: meta.jsonSchema }} />
		</Helmet>
	)
}
export default MetaTagsDefault
