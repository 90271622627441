/* **************************************
 *  LIBRARIES
 * ************************************ */

import React from 'react'
import { Box, chakra, keyframes } from '@chakra-ui/react'

/* **************************************
 *  stroke
 * ************************************ */

// Keyframes helper class must be defined first
const stroke = keyframes`
    100% {
        stroke-dashoffset: 0;
    }
`

/* **************************************
 *  pop
 * ************************************ */

const pop = (scale) => keyframes`
    0%, 100% {
        transform: none;
    }
    60% {
        transform: scale3d(${scale * 1.1}, ${scale * 1.1}, 1);
    }
`

/* **************************************
 *  fill
 * ************************************ */

const fill = (scale) => keyframes`
    100% {
        box-shadow: inset 0px 0px 15px ${scale * 150}px;
    }
`

const getStyledSVG = ({ color, scale, render }) => {
	return {
		'display': render ? 'block' : 'none',
		'borderRadius': '50%',
		'strokeWidth': '6px',
		'w': '90%',
		'h': 'auto',
		'maxW': `${scale * 100}px`,
		'maxH': `${scale * 100}px`,
		'stroke': 'white',
		'strokeMiterlimit': '10',
		'color': color,
		'boxShadow': 'inset 0px 0px 0px 0px',
		'animation': `${fill(scale)} 0.3s ease-in-out 0.4s forwards, ${pop(scale)} 0.3s ease-in-out 0.9s both`,
		'& .checkmark_check, & .checkmark_ex': {
			transformOrigin: '50% 50%',
			strokeDasharray: 48,
			strokeDashoffset: 48,
			animation: `${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards`,
		},
		'& .checkmark_circle': {
			strokeDasharray: scale * 150,
			strokeDashoffset: scale * 150,
			strokeWidth: 4,
			strokeMiterlimit: 10,
			stroke: color,
			fill: 'none',
			animation: `${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards`,
		},
	}
}

const StyledCheckSVG = ({ color, scale, render, ...rest }) => {
	return <chakra.svg sx={{ ...getStyledSVG({ color, scale, render }) }} {...rest} />
}

const CheckExMark = ({ render, translate, color, scale, check, ex, text }) => {
	let _scale = scale ? scale : 1
	let _color = color ? color : check ? '#04d898' : '#ff1761'
	let _hasText = text ? true : false

	return (
		<StyledCheckSVG
			render={render}
			translate={translate}
			// hasText={_hasText}
			color={_color}
			scale={_scale}
			className="checkmark"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 52 52">
			<circle className="checkmark_circle" cx="26" cy="26" r="25" fill="none" />
			{check && <path className="checkmark_check" fill="none" d="M 14.1 27.2l7.1 7.2 16.7-16.8" />}
			{ex && <path className="checkmark_ex" fill="none" d="M 14, 14 L 38,38 M 38,14 L 14,38" />}
		</StyledCheckSVG>
	)
}

export default CheckExMark
